import Uniflow from 'uniflow';
import apiRequest from '../common/api-request';
import localizeErrors from '../models/error-messages/resources';
import RouterActions from '../actions/router';
import RouterStore from '../stores/router';
import buildSearchQuery from './util/build-search-query';

// models
import * as LearningObjectModel from '../models/resource';
import { localizeDescription } from '../models/thumbnail-get-description';

export default Uniflow.createActions({
  search (query) {
    query.where.cursor = query.where.cursor || 'initial';
    this.emit('search-pending', query);
    LearningObjectModel.findAll(query, this.handleSearchResponse);
  },

  update (change) {
    const params = Object.assign({}, RouterStore.state.query, change);

    for (let key of Object.keys(params)) {
      if (key === 'query') {
        key = 'q';
        params.q = params.query;
        delete params.query;
      }
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
      if (params[key] == null || params[key] === '') {
        delete params[key];
      }
    }

    // reset page count if the filters or the ordering have changed
    if (params.page && !change.page) {
      delete params.page;
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  clear () {
    const params = {
      ...RouterStore.state.query,
    };

    for (const key of Object.keys(params)) {
      if (key === 'q' || key === 'sortBy') {
        continue;
      }

      delete params[key];
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  handleSearchResponse (err, results) {
    if (err) {
      this.emit('search-error', err);
    } else {
      this.emit('search-successful', results);
    }
  },

  loadNextPage (cursor, lastQuery) {
    if (cursor) {
      var query = Object.assign({}, lastQuery);
      query.where.cursor = cursor;

      this.search(query);
    }
  },

  clearResults () {
    this.emit('results-reset');
  },

  fetchResultsWithPath (params, cursor, path, options = {}) {
    const query = buildSearchQuery(params, cursor, options);

    if (query.cursor === undefined) {
      this.emit('results-reset');
    }

    const deletePreviousItems = !!options.isPaginated;

    const req = apiRequest({
      path,
      query
    });
    this.emit('results-requested', req, deletePreviousItems);

    req.end((err, res) => {
      if (err) {
        localizeErrors(err);
        return this.emit('results-error', err);
      }
      (res.body.items || []).forEach(localizeDescription);
      this.emit('results-response', req, res, deletePreviousItems);
    });
  },

  dontConfirmOnDelete () {
    this.emit('dont-confirm-on-delete');
  },

  changeResultsView (view) {
    this.emit('change-results-view', view);
  }
});
