import React from 'react';
import SearchInput from './input';
import Select from '@instructure/ui-forms/lib/components/Select';
import formatMessage from 'format-message';
import {
  IconVideoLine,
  IconAudioLine,
  IconImageLine,
  IconModuleLine,
  IconQuizLine,
  IconDiscussionLine,
  IconDocumentLine,
  IconCommonsLine,
  IconCopyLine,
  IconAssignmentLine,
} from '@instructure/ui-icons';
import AnalyticsActions from '../../actions/analytics';
import debounce from 'lodash/function/debounce';
import { resourceTypes } from '../../common/app-constants';
import _ from 'lodash';

const resource = (value, icon, text) => ({
  value,
  icon,
  text: text || (resourceTypes[value].pluralName)
});

const embeddedTypes = [
  resource('document', IconCopyLine),
  resource('video', IconVideoLine),
  resource('audio', IconAudioLine),
  resource('image', IconImageLine)
];

const newTypes = [
  resource('module', IconModuleLine),
  resource('quiz', IconQuizLine),
  resource('assignment', IconAssignmentLine),
  resource('discussion_topic', IconDiscussionLine),
  resource('page', IconDocumentLine)

];

export default class FavoriteResourcesFilter extends React.Component {
  constructor (props) {
    super(props);
    this.throttleUpdateQuery = debounce(this.updateQuery, 400);
    this.currentTypes = props.flags.favorites_tray
      ? newTypes.concat(embeddedTypes).filter(type => props.acceptedCanvasResourceTypes.includes(type.value))
      : embeddedTypes;
    this.currentAllTypeValues = this.currentTypes.map(type => type.value);
    this.showTypeSelector = this.currentTypes.length > 1 || !props.flags.favorites_tray;

    const embeddableTypesRequested = !props.flags.favorites_tray ||
      _.xor(embeddedTypes.map(type => type.value), props.acceptedCanvasResourceTypes).length === 0;
    this.allTypes = [resource('all',
      IconCommonsLine,
      embeddableTypesRequested ? formatMessage('All Embeddable Types') : formatMessage('All Types'))];
  }

  componentDidMount = () => {
    this.props.onChange({ types: this.currentAllTypeValues });
  }

  handleQueryChange = event => {
    this.throttleUpdateQuery(event.target.value);
  }

  updateQuery = query => {
    this.props.onChange({ query });
    AnalyticsActions.newSearchedWord(query);
  }

  handleFilterByChange = (event, el) => {
    this.props.onChange({
      types: el.value === 'all' ? this.currentAllTypeValues : el.value
    });
  }

  render () {
    const options = this.allTypes.concat(this.currentTypes)
      .map(type => <option key={type.value} value={type.value} icon={type.icon}>
        {type.text}</option>);
    return (
      <div className="SearchOptions--Favorite-resource-filter" role="banner">
        {this.showTypeSelector &&
        (<div className="SearchOptions--filter-container">
          <Select
            className="SearchOptions--filter-select"
            name="filterSelect"
            aria-label={formatMessage('Filter results by')}
            title={formatMessage('Filter results by')}
            onChange={this.handleFilterByChange}
            label={false}
            visibleOptionsCount={12}
          >
            {options}
          </Select>
        </div>)}
        <SearchInput
          onChange={this.handleQueryChange}
          defaultValue={this.props.query}
          placeholder={this.props.queryPlaceholder}
          aria-label={this.props.queryAriaLabel}
          classNames="SearchInput--Favorite-resource-filter"
        />
      </div>
    );
  }
}
