import React from 'react';
import formatMessage from 'format-message';
import { Tray } from '@instructure/ui-tray';
import { List } from '@instructure/ui-list';
import { CloseButton } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { IconQuestionLine } from '@instructure/ui-icons';
import { Heading } from '@instructure/ui-heading';

import panda from '../../assets/svg/panda.svg';
import { getLocalizedUrl } from '../common/localized-urls';
import Link from './common/link';

const items = [
  {
    label: formatMessage('How do I use Commons?'),
    href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/How-do-I-use-Commons/ta-p/1795'),
  },
  {
    label: formatMessage('How do I import and view a Commons resource in Canvas?'),
    href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/How-do-I-import-and-view-a-Commons-resource-in-Canvas/ta-p/1808'),
  },
  {
    label: formatMessage('What types of content licenses are available in Commons?'),
    href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-types-of-content-licenses-are-available-in-Commons/ta-p/1809'),
  },
  {
    label: formatMessage('How do I share a resource to Commons?'),
    href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/How-do-I-share-a-resource-to-Commons/ta-p/1798'),
  },
  {
    label: formatMessage('What types of document and media files can be shared to Commons?'),
    href: getLocalizedUrl('https://community.canvaslms.com/t5/Commons/What-types-of-document-and-media-files-can-be-shared-to-Commons/ta-p/1792'),
  },
  {
    label: formatMessage('How do I view updates to resources I previously imported from Commons?'),
    href:
    getLocalizedUrl('https://community.canvaslms.com/t5/Commons/How-do-I-view-updates-to-resources-I-previously-imported-from/ta-p/1797'),
  },
];

const GuidesLink = ({ isWithinText }) => (
  <Link
    target="_blank"
    isWithinText={isWithinText}
    href={getLocalizedUrl('https://community.canvaslms.com/t5/Commons/tkb-p/commons')}
  >
    {formatMessage('Canvas Commons Guides')}
  </Link>
);

const Line = ({ margin }) => (
  <div
    style={{
      height: '1px',
      backgroundColor: '#C7CDD1',
      margin,
    }}
  />
);

const PandaHeader = () => (
  <Flex justifyItems="center">
    <Flex.Item>
      <div style={{ marginTop: '80px' }}>
        <img src={panda} alt={formatMessage('Commons Help')} />
      </div>
    </Flex.Item>
  </Flex>
);

const Description = () => (
  <p
    style={{
      lineHeight: '24px',
      textAlign: 'center',
      marginBottom: '8px',
    }}
  >
    {formatMessage(
      'We collected a few useful articles for you, but you can always go and learn more from our'
    )}{' '}
    <GuidesLink />
    {'.'}
  </p>
);

const Links = () => (
  <List itemSpacing="small" isUnstyled margin="x-small 0">
    {items.map(({ href, label }) => (
      <List.Item key={href}>
        <Flex>
          <Flex.Item align="start">
            <IconQuestionLine
              style={{
                marginRight: '12px',
              }}
            />
          </Flex.Item>
          <Flex.Item shouldShrink shouldGrow>
            <Link target="_blank" isWithinText={false} href={href}>
              {label}
            </Link>
          </Flex.Item>
        </Flex>
      </List.Item>
    ))}
  </List>
);

export const Help = ({ open, onClose }) => {
  return (
    <Tray
      label={formatMessage('Help')}
      open={open}
      onDismiss={onClose}
      size="regular"
      placement="end"
      themeOverride={{
        zIndex: 99999,
      }}
      shouldCloseOnDocumentClick
    >
      <CloseButton
        size="medium"
        placement="end"
        onClick={onClose}
        data-automation="HelpCloseButton"
        screenReaderLabel={formatMessage('Close')}
      />
      <Flex direction="column" margin="0 medium">
        <Flex.Item margin="0 0 x-small 0">
          <PandaHeader />
        </Flex.Item>
        <Flex.Item margin="x-small 0 0 0" textAlign="center" shouldGrow>
          <Heading level="h3">
            {formatMessage('Need more help with Commons?')}
          </Heading>
        </Flex.Item>
        <Flex.Item margin="small 0 0 0">
          <Description />
        </Flex.Item>
        <Flex.Item>
          <Line margin="16px 0 14px 0" />
        </Flex.Item>
        <Flex.Item>
          <Links />
        </Flex.Item>
        <Flex.Item>
          <Line margin="14px 0 0 0" />
        </Flex.Item>
      </Flex>
      <div
        style={{
          padding: '18px 0px 200px 24px',
        }}
      >
        <GuidesLink isWithinText={false} />
      </div>
    </Tray>
  );
};
