import debounce from 'lodash/function/debounce';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import formatMessage from 'format-message';
import _ from 'lodash';
import Select from '@instructure/ui-forms/lib/components/Select';
import { IconFilterLine } from '@instructure/ui-icons';

import SearchFilters from './search-filters';
import SearchInput from './input';
import Tray from '../common/tray';
import AnalyticsActions from '../../actions/analytics';
import {
  SORT_RECENT,
  SORT_MATCHING,
  SORT_APPROVED,
  SORT_FAVORITE,
  SORT_DOWNLOADS,
  SORT_TITLE,
  SORT_AUTHOR,
  SORT_EMAIL,
  SORT_REPORTS
} from '../../constants/search';
import Button from '../common/button';
import { getState } from '../../store';

const PANEL_FILTERS = 'filters';

const sortLabels = {
  [SORT_MATCHING]: formatMessage({
    default: 'Most Relevant',
    description:
      'Label for option to sort resources with the closest match to the query first'
  }),
  [SORT_RECENT]: formatMessage({
    default: 'Latest',
    description:
      'Label for option to sort resources most recently modified first'
  }),
  [SORT_APPROVED]: formatMessage({
    default: 'Approved',
    description: 'Label for option to sort by approved resources first'
  }),
  [SORT_FAVORITE]: formatMessage({
    default: 'Most Favorited',
    description: 'Label for option to sort by most favorited resources first'
  }),
  [SORT_DOWNLOADS]: formatMessage({
    default: 'Most Downloaded',
    description: 'Label for option to sort most downloaded resources first'
  }),
  [SORT_REPORTS]: formatMessage({
    default: 'Most reported',
    description: 'Label for option to sort most reported resources first'
  }),
  [SORT_AUTHOR]: formatMessage({
    default: 'Author',
    description: 'Label for option to sort by author name'
  }),
  [SORT_EMAIL]: formatMessage({
    default: 'Email',
    description: 'Label for option to sort by author email'
  }),
  [SORT_TITLE]: formatMessage({
    default: 'Commons Resource',
    description: 'Label for option to sort aplhabetically by resource title'
  })
};

export default class SearchOptions extends React.Component {
  static displayName = 'SearchOptions'

  static propTypes = {
    account: PropTypes.object.isRequired,
    searchPrivateObjects: PropTypes.bool,
    searchFavoriteObjects: PropTypes.bool,
    queryPlaceholder: PropTypes.string,
    queryArialLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    gradeIds: PropTypes.arrayOf(PropTypes.string),
    scopeIds: PropTypes.arrayOf(PropTypes.string),
    subjectIds: PropTypes.arrayOf(PropTypes.string),
    contentCategoryTypes: PropTypes.arrayOf(PropTypes.string),
    sortBy: PropTypes.oneOf([
      SORT_RECENT,
      SORT_MATCHING,
      SORT_APPROVED,
      SORT_FAVORITE,
      SORT_DOWNLOADS,
      SORT_REPORTS,
      SORT_TITLE,
      SORT_AUTHOR,
      SORT_EMAIL
    ]),
    showPublic: PropTypes.bool,
    canShowPublic: PropTypes.bool,
    canShowFeatured: PropTypes.bool,
    canShowApprovedContent: PropTypes.bool,
    consortiums: PropTypes.object,
    groups: PropTypes.object || PropTypes.array,
    extraSortOptions: PropTypes.bool
  }

  static defaultProps = {
    query: ''
  }

  static FilterButton ({
    className,
    expanded,
    label,
    hideLabel,
    showLabel,
    ...rest
  }) {
    return (
      <div className="SearchOptions-filterButton">
        <Button
          {...rest}
          className="SearchOptions-filter"
          display={Button.display.block}
          renderIcon={IconFilterLine}
          screenReaderLabel={expanded ? hideLabel : showLabel}
        >
          {label}
        </Button>
      </div>
    );
  }

  constructor (props) {
    super(props);
    this.throttleUpdateQuery = debounce(this.updateQuery.bind(this), 400);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.toggleFiltersPanel = this.toggleFiltersPanel.bind(this);
    this.toggleSortPanel = this.toggleSortPanel.bind(this);
    this.hideSortPanel = this.hideSortPanel.bind(this);
    this.handleSortByChange = this.handleSortByChange.bind(this);

    this.state = {
      panel: null
    };
  }

  handleQueryChange (event) {
    this.throttleUpdateQuery(event.target.value);
  }

  handleSortByChange (event, el) {
    this.props.onChange({ sortBy: el.value });
  }

  updateQuery (query) {
    this.props.onChange({ query });
    AnalyticsActions.newSearchedWord(query);
  }

  componentWillUnmount () {
    this.throttleUpdateQuery.cancel();
  }

  toggleFiltersPanel () {
    this.setState({
      panel: this.state.panel === PANEL_FILTERS ? null : PANEL_FILTERS
    });
  }

  toggleSortPanel () {
    this.setState({ panel: null });
  }

  hideSortPanel () {
    const sortToggleButton = ReactDOM.findDOMNode(this).querySelector(
      '.SearchOptions-sortToggleButton'
    );
    if (sortToggleButton) sortToggleButton.focus();
    this.setState({ panel: null });
  }

  buildSelectOptions = () => {
    let options = [
      <option key={SORT_MATCHING} value={SORT_MATCHING}>
        {sortLabels[SORT_MATCHING]}
      </option>,
      <option key={SORT_RECENT} value={SORT_RECENT}>
        {sortLabels[SORT_RECENT]}
      </option>,
      <option key={SORT_FAVORITE} value={SORT_FAVORITE}>
        {sortLabels[SORT_FAVORITE]}
      </option>,
      <option key={SORT_DOWNLOADS} value={SORT_DOWNLOADS}>
        {sortLabels[SORT_DOWNLOADS]}
      </option>
    ];

    if (this.props.extraSortOptions) {
      options = [
        <option key={SORT_MATCHING} value={SORT_MATCHING}>
          {sortLabels[SORT_MATCHING]}
        </option>,
        <option key={SORT_RECENT} value={SORT_RECENT}>
          {sortLabels[SORT_RECENT]}
        </option>,
        <option key={SORT_FAVORITE} value={SORT_FAVORITE}>
          {formatMessage({
            default: 'Favorites',
            description: 'Label for option to sort by favorite count'
          })}
        </option>,
        <option key={SORT_DOWNLOADS} value={SORT_DOWNLOADS}>
          {formatMessage({
            default: 'Downloads',
            description: 'Label for option to sort by download count'
          })}
        </option>,
        <option key={SORT_TITLE} value={SORT_TITLE}>
          {sortLabels[SORT_TITLE]}
        </option>,
        <option key={SORT_AUTHOR} value={SORT_AUTHOR}>
          {sortLabels[SORT_AUTHOR]}
        </option>,
        <option key={SORT_EMAIL} value={SORT_EMAIL}>
          {sortLabels[SORT_EMAIL]}
        </option>,
        <option key={SORT_APPROVED} value={SORT_APPROVED}>
          {sortLabels[SORT_APPROVED]}
        </option>
      ];
    }
    const shouldShowReportContentSorting = _.get(getState(), 'featureFlags.flags.report_content');
    if (shouldShowReportContentSorting) {
      options.push(
        <option key={SORT_REPORTS} value={SORT_REPORTS}>
          {sortLabels[SORT_REPORTS]}
        </option>
      );
    }
    return options;
  }

  renderSelect = () => {
    const sort =
      this.props.sortBy || (this.props.query ? SORT_MATCHING : SORT_RECENT);

    return (
      <Select
        className="SearchOptions-sortSelect"
        name="sortBy"
        aria-label={formatMessage('Sort results by')}
        title={formatMessage('Sort results by')}
        onChange={this.handleSortByChange}
        selectedOption={{ value: sort, label: sortLabels[sort] }}
        renderLabel=" "
      >
        {this.buildSelectOptions()}
      </Select>
    );
  }

  render () {
    const { queryPlaceholder, queryArialLabel, query } = this.props;

    const { panel } = this.state;
    const showFilters = this.props.showFilters || panel === PANEL_FILTERS;

    return (
      <React.Fragment>
        <div className="SearchOptions App-main-inner-main-content-width" role="banner" aria-live="polite" aria-atomic="true">
          <SearchInput
            onChange={this.handleQueryChange}
            defaultValue={query}
            placeholder={queryPlaceholder}
            aria-label={queryArialLabel}
          />

          <div className="SearchOptions-sortBy">{this.renderSelect()}</div>

          <SearchOptions.FilterButton
            expanded={showFilters}
            showLabel={formatMessage('Show filters')}
            hideLabel={formatMessage('Hide filters')}
            aria-controls="SearchOptions-filtersOptions--tray"
            label={formatMessage({
              default: 'Filter',
              description:
                'Filter the search results, like course, module, video and grade levels like, 1st grade, undergraduate, etc.'
            })}
            onClick={this.toggleFiltersPanel}
          />
        </div>
        <Tray
          id="SearchOptions-filtersOptions"
          label={formatMessage('Filter Results')}
          className="SearchOptions-panel"
          show={showFilters}
          onClose={() => {
            this.setState({ panel: null });
          }}
        >
          <SearchFilters {...this.props} />
        </Tray>
      </React.Fragment>
    );
  }
}
