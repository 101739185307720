import { SORT_RECENT, SORT_MATCHING } from '../../constants/search';

const EXCLUDED_QUERY_KEYS = ['page'];

const isKeyNotExcluded = (value) => {
  return !EXCLUDED_QUERY_KEYS.includes(value);
};

const isValidPaginationOptions = (options) => {
  return options.resultsPerPage > 0;
};

const calculatePaginatedCursor = (page, options) => {
  if (!page || page < 1) {
    return null;
  }

  return (page - 1) * options.resultsPerPage;
};

const addPaginationToQuery = (query, params, options) => {
  if (!isValidPaginationOptions(options)) {
    return;
  }
  const paginatedCursor = calculatePaginatedCursor(params.page, options);
  if (paginatedCursor !== null) {
    query.cursor = paginatedCursor;
  }

  query.size = options.resultsPerPage;
};

const addCursorToQuery = (query, cursor) => {
  if (cursor) {
    query.cursor = JSON.parse(cursor); // cursor is a string not an object
  }
};

const addSortByToQuery = (query) => {
  if (!query.sortBy) {
    query.sortBy = query.q ? SORT_MATCHING : SORT_RECENT;
  }
};

const buildSearchQuery = (params, cursor, options) => {
  const query = Object.keys(params).filter(isKeyNotExcluded).reduce((query, key) => {
    query[key] = Array.isArray(params[key])
      ? params[key].join(',')
      : params[key];
    return query;
  }, {});

  if (options.isPaginated) {
    addPaginationToQuery(query, params, options);
  } else {
    addCursorToQuery(query, cursor);
  }

  addSortByToQuery(query);
  return query;
};

export default buildSearchQuery;
